import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { OfferFields } from '../fragment';

import OfferCard from 'components/Card/Offer';
import { ContentWrapper, ColorPrimary } from 'components/Common';
import { Wrapper, Title, Subtitle, OfferList } from '../styles';

interface OfferQuery {
  offers: {
    edges: {
      node: OfferFields;
    }[];
  };
}

const creditsData = graphql`
  {
    offers: allMarkdownRemark(
      sort: { fields: [frontmatter___date] }
      filter: { frontmatter: { type: { eq: "loans" } } }
    ) {
      edges {
        node {
          ...offerFields
        }
      }
    }
  }
`;

type Props = {
  id: string;
};

const LoansOfferView: FC<Props> = ({ id }) => {
  const { offers } = useStaticQuery<OfferQuery>(creditsData);

  return (
    <Wrapper id={id}>
      <ContentWrapper>
        <Title>
          Pożyczki dla firm z <ColorPrimary>Simple Finance</ColorPrimary> to
          możliwość pozyskania niezbędnych środków na prowadzenie Twojego
          biznesu
        </Title>
        <Subtitle>Pożyczki dostępne w naszej ofercie</Subtitle>
        <OfferList>
          {offers.edges.map(({ node }) => (
            <OfferCard
              key={node.id}
              to={node.fields.slug}
              fluid={node.frontmatter.cover.childImageSharp.fluid}
              title={node.frontmatter.title}
              text={node.excerpt}
            />
          ))}
        </OfferList>
      </ContentWrapper>
    </Wrapper>
  );
};

export default LoansOfferView;
