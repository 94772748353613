import React, { FC } from 'react';
import { PageProps } from 'gatsby';

import Head from 'views/Head';
import LoansHeader from 'views/CreditsHeader';
import LoansOffer from 'views/Offer/Loans';

const LoanPage: FC<PageProps> = () => (
  <>
    <Head title="Pożyczki" />
    <LoansHeader id="loans_hero" title="Pożyczki" />
    <LoansOffer id="pozyczki" />
  </>
);

export default LoanPage;
